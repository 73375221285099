import ContentContainer from "../../components/content/ContentContainer";
import Tabs from "../../components/tabs/Tabs";
import MemberInsights from "./MemberInsights/MemberInsights";
import Articles from "./Articles/Articles";
import Messages from "./Messages/Messages";
import ClassifiedAssets from "./ClassifiedAssets/ClassifiedAssets";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { GenericBadge } from "../../components/widgets/alerts/AlertElements";
import { ThemeTooltip } from "../../components/settings/theme/ThemeTooltip";
import { useGetAdminConfig } from "./adminHooks";

export type IAssets = {
	url?: string | undefined;
	password?: string | undefined;
	image?: string | undefined;
	infoImage?: string | undefined;
	infoUrl?: string | undefined;
	isBroker?: boolean;
}

const AdminHome = (props: any) => {
	const { path } = props.match;
	const history = useHistory();
	const [showAdmin, setShowAdmin] = useState(true);
	const adminConfig = useGetAdminConfig();

	// @TODO: vk - this is a workaround for roster project using the old route - can be removed after merging
	useEffect(() => {
		if (path === "/aor-messages") {
			history.push("/admin");
			setShowAdmin(false);
		}

		return () => {
			setShowAdmin(true);
		};
	}, []);

	const buildTabContent = () => {
		let tabs = [
			<div key="messages" data-label="Messages">
				<div className="mt-4">
					<Messages />
				</div>
			</div>,
			<div key="member-insights" data-label="Member Insights">
				<div className="mt-4">
					<MemberInsights />
				</div>
			</div>,
		];

		// If user is GSMLS, do not show articles or resource materials tabs
		if (!adminConfig.isGsmls) {
			tabs.push(
				<div key="articles" data-label="Articles">
					<div className="mt-4">
						<Articles />
					</div>
				</div>
			);

			// Only show the resource materials tab if url and password exist
			if (adminConfig.resourceMaterialsData.url && adminConfig.resourceMaterialsData.password) {
				tabs.push(
					<div key="resource-materials" data-label="Resource Materials">
						<div className="mt-4">
							<ClassifiedAssets assetsData={adminConfig.resourceMaterialsData} />
						</div>
					</div>
				);
			}
		}

		return tabs;
	};

	return showAdmin ? (
		<ContentContainer title={adminConfig.isBroker ? "Broker Center" : "AOR Center"} actions={null}>
			<Tabs section="admin" children={buildTabContent()} rootPath={path.replace("/:urlTab?/:id?", "")} badges={[{ tab: "Member Insights", badge: <GenericBadge title="New!" className="bg-red-500 ml-2" /> }]} />
			<ThemeTooltip />
		</ContentContainer>
	) : (
		<></>
	);
};

export default AdminHome;
