import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAllSystemNotifications, selectAllSystemNotifications } from "../../systemNotification/systemNotificationsSlice";
import { TableLoader } from "../../../components/widgets/SkeletonScreens";
import { ColumnDirective, ColumnsDirective, DataStateChangeEventArgs, GridComponent, Inject, Page, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import dayjs from "dayjs";
import ReactSlidingPane from "react-sliding-pane";
import Message from "./Message";
import { debounce, isArray } from "lodash";
import { selectReadSystemNotifications } from "../../user/selectors";
import { useAppDispatch } from "../../../app/hooks";
import { setUserPrefs } from "../../user/userPrefsSlice";
import { show } from "../../notification/notificationSlice";
import { mace_url } from "../../../adapters";
import axios from "axios";
import { PencilIcon, PlusCircleIcon, ReplyIcon, TrashIcon, XIcon } from "@heroicons/react/solid";
import { useGetAdminConfig } from "../adminHooks";
import ErrorMessage from "../../../components/widgets/ErrorMessage";
import { ClipLoader } from "react-spinners";
import { MailIcon } from "@heroicons/react/outline";

const placeholder = "Search by message text";
interface INotificationItemQ {
	data: { results: any[]; totalResults: string | number };
}

const Messages = () => {
	const dispatch = useAppDispatch();
	const readNotifications = useSelector(selectReadSystemNotifications);
	const gridControl = useRef<any>();
	const [isProcessing, setIsProcessing] = useState(false);
	const [showSidePanel, setShowSidePanel] = useState(false);
	const [messageId, setMessageId] = useState("");
	const [activePage, setActivePage] = useState(0);
	const [sortParams, setSortParams] = useState<any[]>([]);
	const [firstLoad, setFirstLoad] = useState(true);
	const [data, setData] = useState<{ result: INotificationItemQ["data"]["results"]; count: string | number }>();
	const [error, setError] = useState<string>("");
	const [searchTerm, setSearchTerm] = useState<string>("");
	const systemNotifications = useSelector(selectAllSystemNotifications);
	const adminConfig = useGetAdminConfig();
	const messagesCriteria = adminConfig.criteria.messages;

	useEffect(() => {
		fetchData();
		return () => {};
	}, [activePage, sortParams, searchTerm]);

	const fetchData = () => {
		if (messagesCriteria) {
			if (searchTerm) {
				messagesCriteria.push({ field: "message", op: "Contains", values: [searchTerm] });
			}
			axios.post(`${mace_url}api/app/SystemNotification/q`, {
				pageId: activePage,
				pageSize: adminConfig.pageSize,
				orderBy: sortParams,
				criteria: messagesCriteria
			}).then((response: any) => {
				if (gridControl.current) {
					gridControl.current.hideSpinner();
				}
				setData({
					result: response.data.results,
					count: response.data.totalResults,
				});
				setFirstLoad(false);
			});
		} else {
			setFirstLoad(false);
			setError("No messages criteria found for this user")
		}
		setIsProcessing(false);
	};

	const dateFormatter = (field: string, data: any) => {
		return dayjs(data[field]).format("MM/DD/YYYY");
	};

	const messageFormatter = (field: string, data: any) => {
		return data.message.replace(/(<([^>]+)>)/gi, "");
	};

	const handleStateChange = (state: DataStateChangeEventArgs) => {
		if (state.action) {
			if (state.action.requestType === "sorting") {
				if ("sorted" in state) {
					let sortOrder: any[] = [];
					state.sorted?.forEach((sort) => {
						sortOrder.push({
							field: sort.name,
							direction: sort.direction === "ascending" ? "Asc" : "Desc",
						});
					});
					setSortParams(sortOrder);
				} else {
					setSortParams([]);
				}
			} else if (state.action.requestType === "paging") {
				if ((state.action as any).currentPage - 1 !== activePage) {
					setActivePage((state.action as any).currentPage - 1);
				}
			}
		} else {
			state.skip = 1;
		}
	};

	/*** Column Templates  ***/
	const nameTemplate = (props: any) => {
		let field = props.column.field; // this will be the actual column
		let tag = props[field] ?? "No Name";
		if (isArray(tag)) {
			tag = tag.join(", ");
		}
		return <div className="cursor-pointer" onClick={() => handleEditMessage(props.id)}>{tag}</div>;
	};

	const actionsTemplate = (props: any) => {
		const showUnread = readNotifications.find((notification: string) => notification === props.id && dayjs().isBefore(dayjs(props.expirationDate)));
		return (
			<>
				<span className="relative z-0 inline-flex shadow-sm rounded-sm">
					<button title="Edit" type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-sm border border-default bg-primary" onClick={() => handleEditMessage(props.id)}>
						<span className="sr-only">Edit</span>
						<PencilIcon className="w-4 h-4 text-header" aria-hidden="true" />
					</button>
					<button title="Delete" type="button" className="relative inline-flex items-center px-2 py-2 rounded-r-sm border border-default bg-primary" onClick={() => handleDeleteMessage(props.id)}>
						<span className="sr-only">Delete</span>
						<TrashIcon className="w-4 h-4 text-red-500" aria-hidden="true" />
					</button>
					{showUnread &&
						<button title="Mark as Unread" type="button" className="relative inline-flex items-center px-2 py-2 rounded-r-sm border border-default bg-primary" onClick={() => handleMarkAsUnread(props.id)}>
							<span className="sr-only">Mark as Unread</span>
							<ReplyIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
						</button>}
				</span>
			</>
		);
	};

	/*** Actions ***/
	const handleEditMessage = (messageId: string) => {
		setMessageId(messageId);
		setShowSidePanel(true);
	};

	const handleNewMessage = () => {
		setMessageId("");
		setShowSidePanel(true);
	};

	const handleMarkAsUnread = (messageId: string) => {
		if (messageId) {
			var index = readNotifications.indexOf(messageId);
			if (index !== -1) {
				const newReadNotifications = [...readNotifications].filter((notification) => notification !== messageId);
				dispatch(
					setUserPrefs({
						key: "systemNotifications.read",
						value: newReadNotifications,
					})
				);
			}
		}
	};

	const handleDeleteMessage = (messageId: string) => {
		if (window.confirm("Are you sure you wish to delete this message? In doing so, this will also be removed from all agents' views.")) {
			axios
				.delete(mace_url + "api/app/SystemNotification/" + messageId)
				.then(() => {
					dispatch(
						show({
							show: true,
							title: "Success",
							message: "Message deleted succesfully",
							status: "success",
							position: "popover",
							autoHide: 5000,
							confirm: false,
							notificationId: null,
						})
					);
					dispatch(fetchAllSystemNotifications());
				})
				.catch((e) => {
					dispatch(
						show({
							show: true,
							title: "Error",
							message: "There was a problem deleting the message. " + e.message,
							status: "error",
							position: "popover",
							autoHide: 5000,
							confirm: false,
							notificationId: null,
						})
					);
					console.error("Error deleting message", e);
				});
		}
	};

	const handleResetSearch = () => {
		const messageSearchInput = document.getElementById("message-search") as HTMLInputElement;
		if (messageSearchInput) {
			messageSearchInput.value = "";
		}
		setSearchTerm("");
	}

	const handleSearch = (searchTerm: string) => {
		debounceSearch.cancel();
		setSearchTerm(searchTerm);
	};
	const debounceSearch = debounce(handleSearch, 500);
	const debouncedHandleSearch = useCallback(debounceSearch, []);

	const handleToolbarClick = (args: any) => {
		if (args.item.id === "RefreshView") {
			fetchData();
		}
	};

	const closeSlider = () => {
		setShowSidePanel(false);
		fetchData();
	};

	return firstLoad ? (
		<TableLoader />
	) : error ? (
		<ErrorMessage message={error} />
	) : (
		<>
			<div className="inline-flex mb-4 pl-2 opacity-90"><MailIcon className="h-8 w-8 mr-2" /><h3 className="font-medium text-2xl">Messages</h3></div>
			<div className="crmls-table-main-actions">
				<div className="crmls-actions-left w-full lg:w-3/4">
					<button type="button" className="action-button bg-green-600 w-full sm:w-auto" onClick={handleNewMessage}>
						<PlusCircleIcon className="h-4 w-4 mr-1" /> New Message
					</button>
				</div>
				<div className="crmls-actions-right w-full lg:w-1/4">
					<div className="relative w-full">
						<input
							id="message-search"
							type="text"
							className="w-ful input-registered-required"
							placeholder={placeholder}
							onChange={(e) => {
								setIsProcessing(true);
								debouncedHandleSearch(e.target.value);
							}}
						/>
						{!isProcessing && searchTerm && <XIcon className="cursor-pointer w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-3" onClick={handleResetSearch} />}
						{isProcessing && <div  className="absolute top-1/2 transform -translate-y-1/2 right-3"><ClipLoader aria-label="Loading Results" size={14} /></div>}
					</div>
				</div>
			</div>
			<div className="syncfusion-grid w-full">
				<GridComponent
					ref={gridControl}
					dataStateChange={handleStateChange}
					dataSource={data}
					allowPaging={true}
					pageSettings={{ pageSize: 10 }}
					allowSorting={true}
					enablePersistence={false}
					resizeSettings={{ mode: "Auto" }}
					dataBound={() => {
						gridControl.current.autoFitColumns([]);
					}}
					toolbar={[{ text: "Refresh", tooltipText: "Refresh List", prefixIcon: "e-refresh", id: "RefreshView" }]}
					toolbarClick={handleToolbarClick}
				>
					<ColumnsDirective>
						<ColumnDirective field="title" headerText="Title" template={nameTemplate} isPrimaryKey={true} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="message" valueAccessor={messageFormatter} headerText="Message" clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="systemNotificationStatus.name" headerText="Type" clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="createdOn" headerText="Created On" valueAccessor={dateFormatter} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="modifiedOn" headerText="Last Modified" valueAccessor={dateFormatter} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="expirationDate" headerText="Expires" valueAccessor={dateFormatter} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="actions" headerText="Actions" template={actionsTemplate} textAlign="Right" allowSorting={false} clipMode="EllipsisWithTooltip" />
					</ColumnsDirective>
					<Inject services={[Page, Sort, Toolbar]} />
				</GridComponent>
			</div>

			<ReactSlidingPane
				isOpen={showSidePanel}
				width={"85%"}
				title={messageId ? "Message Details" : "New Message"}
				onRequestClose={() => {
					setMessageId("");
					setShowSidePanel(false);
				}}
			>
				<Message id={messageId} closeSlider={closeSlider} />
			</ReactSlidingPane>
		</>
	);
};

export default Messages;
