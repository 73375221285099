import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MailIcon } from "@heroicons/react/outline";
import { selectReadNotifications, userProfile } from "../user/selectors";
import { notifications_url } from "../../adapters";
import axios from "axios";

const NotificationIcon = ({ size }: { size: number }) => {
	const profile = useSelector(userProfile);
	const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
	const readNotifications = useSelector(selectReadNotifications);
	const [fetchedNotifications, setFetchedNotifications] = useState<any>([]);

	const fetchNewCount = () => {
		axios.post(notifications_url + "api/app/EmailRequestMessage/q", {
			pageId: 0,
			pageSize: 9999,
			fields: ["id"],
			criteria: [
				{
					field: "memberId",
					op: "Equal",
					values: [profile.member.id],
				},
			],
		}).then((response) => {
			setFetchedNotifications(response.data.results ?? []);
		});
	}

	useEffect(() => {
		let checkNewNotifications: any;
		if (profile && profile.member?.id) {
			// Make initial request for new notifications, and set interval to check every 5 min
			fetchNewCount();
			checkNewNotifications = setInterval(() => {
				fetchNewCount();
			}, 300000);
		}

		return () => {
			if (checkNewNotifications) {
				clearInterval(checkNewNotifications);
			}
		}
	}, [profile]);

	useEffect(() => {
		if (fetchedNotifications) {
			if (fetchedNotifications.length > 0 && readNotifications && readNotifications.length > 0) {
				const hasUnread = fetchedNotifications.filter(({ ID }: { ID: string }) => !readNotifications.includes(ID));
				setUnreadNotificationsCount(hasUnread.length);
			} else {
				setUnreadNotificationsCount(fetchedNotifications.length);
			}
		}
	}, [fetchedNotifications, readNotifications]);

	return (
		<Link to="/notifications">
			<div className="relative p-1 rounded-full text-secondary hover:text-primary cursor-pointer">
				<MailIcon className={`h-${size} w-${size}`} aria-hidden="true" />
				{unreadNotificationsCount > 0 && (
					<span className={`absolute -top-1 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full ${unreadNotificationsCount > 99 ? "-right-2" : unreadNotificationsCount < 10 ? "w-5 right-0" : "-right-1"}`}>
						{unreadNotificationsCount > 99 ? "99+" : unreadNotificationsCount}
					</span>
				)}
			</div>
		</Link>
	);
};

export default NotificationIcon;
